/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {getTokenRefreshData} from '@exc/auth';
import type {Metrics} from '@adobe/exc-app/metrics';

/**
 * Logs the token stats for debugging purposes. This will only happen if the
 * token can be parsed and has the necessary metadata. Additionally, it will
 * only log if the token is past its refresh time.
 */
export const debugTokenStats = (
  metrics: Metrics,
  message: string,
  token?: string,
  extraData?: any
): void => {
  // No token was provided??
  if (!token) {
    metrics.warn(`Token stats: ${message}: No token`, extraData);
    return;
  }
  const refreshData = getTokenRefreshData(token);
  const logPayload = {...extraData, ...refreshData};
  const {expiresAt, refreshAt} = refreshData;

  // The token is past its expiration.
  if (expiresAt < Date.now()) {
    metrics.warn(`Token stats: ${message}: Token past expiration`, logPayload);
    return;
  }
  // The token is past the refresh date.
  if (refreshAt < Date.now()) {
    metrics.warn(`Token stats: ${message}: Token past refresh date`, logPayload);
  }
};
